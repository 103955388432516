


















































































































































.details > div > span {
    @apply font-normal;
}

.address > p {
    @apply text-gray-600;
}

.contact-avatar {
    @apply w-10 h-10 rounded-full mr-4 overflow-hidden bg-gray-300 border border-gray-300 lg:w-20 lg:h-20;
}
