





























































































































@keyframes onAutoFillStart { from {/**/} to {/**/} }
@keyframes onAutoFillCancel { from {/**/} to {/**/} }

input:-webkit-autofill {
    animation-name: onAutoFillStart;
    transition: background-color 0.1s ease-in-out 0s;
}
input:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
}
