




































.nav-link {
    @apply px-4 py-3 text-gray-700 text-sm flex items-center relative transition-all hover:text-brand-regular;
}

