












































































































.avatar {
    @apply mb-2 w-16 h-16 rounded-full mx-auto overflow-hidden bg-gray-300 border border-gray-300 lg:w-24 lg:h-24;
}
.heading {
    @apply text-lg mb-2 font-bold mx-auto;
}
