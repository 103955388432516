













































.dropdown {
    @apply absolute flex flex-col right-0 shadow-lg bg-white rounded-md;
    @apply border-2 border-gray-300 z-20 min-w-content py-2 w-40;
    > button,
    > a {
        color: #2d3748;
        font-size: 0.875rem;
        font-weight: 500;
        padding: 0.375rem 0.75rem;
        text-align: left;

        &:hover {
            background: #f7fafc;
        }
    }
}
