
































.navigation-section {
    @apply flex p-6 bg-white rounded-sm shadow-sm fixed bottom-0 left-0 w-full border-t-2 z-20 md:relative md:mb-4;
}
