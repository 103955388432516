






















































































































































































.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: scale(0.9) translateX(30px);
}
.deleted {
    @apply bg-gray-200 ring-inset ring ring-red-300;
}
.table-order-chevron {
    @apply ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300 w-5 h-5 flex items-center justify-center;
}
.table-column-label {
    @apply text-gray-700 text-xs uppercase tracking-widest overflow-hidden;
}
