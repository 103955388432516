



























































.notification-text a {
    @apply font-bold text-blue-600 hover:underline;
}

.notification-success { @apply bg-emerald-400; }
.notification-error { @apply bg-red-400; }
.notification-warning { @apply bg-orange-400; }
.notification-info { @apply bg-sky-400; }
.notification-read { @apply bg-gray-400; }

.action-button {
    @apply text-blue-600 hover:text-white text-center text-xs
    border border-blue-600
    rounded-full hover:bg-blue-600
    focus:ring-4 focus:outline-none focus:ring-blue-300
    px-2.5 py-1;
}
