$_timing: 350ms;
$_easing: ease-in-out;

.fade-enter-active {
    animation: fade $_timing $_easing;
}

.fade-leave-active {
    animation: fade $_timing $_easing reverse;
}

.dropdown-enter-active {
    animation: dropdown 100ms ease-in-out;
}

.dropdown-leave-active {
    animation: fade 100ms $_easing reverse;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes dropdown {
    from {
        opacity: 0;
        transform: scale(0.6);
        transform-origin: top right;
    }

    to {
        opacity: 1;
        transform: scale(1);
        transform-origin: top right;
    }
}
