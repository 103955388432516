








































































































.multiselect__tag {
    @apply bg-brand-regular;
}

.multiselect__tag-icon:after {
    @apply text-white;
}

.multiselect__tag-icon:hover {
    @apply bg-brand-dark;
}

.multiselect__option--highlight,
.multiselect__option--highlight:after {
    @apply bg-brand-light;
}
