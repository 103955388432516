


































































.icon {
    position: absolute;
    right: 4px;
    top: 4px;
}
