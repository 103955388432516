






























































.notification-button {
    @apply inline-flex relative p-3 rounded text-gray-700 border border-white
}

.unread-dot {
    @apply absolute flex h-3 w-3 top-0.5 right-0.5
}
