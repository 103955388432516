





























































































.modal,
.modal__panel {
    transition: all 300ms ease-in-out;
}

.modal {
    @apply items-center flex h-screen w-screen fixed justify-center z-50 inset-y-0 inset-x-0 md:py-16 md:px-8;
    background: rgba(0, 0, 0, 0.6);
}

.modal-leave-active,
.modal-enter {
    opacity: 0;

    .modal__panel {
        transform: scale(0.95) translateY(25px);
    }
}
