








































.button-icon {
  @apply text-white bg-gray-400 rounded-full w-6 h-6 flex items-center justify-center;
}
