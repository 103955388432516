



















































































.item {
  transition: font-weight, background, 0.2s ease-in-out;

  @apply capitalize px-6 py-3 cursor-pointer border border-b border-gray-300 ;

  &:hover {
    @apply bg-gray-300 font-bold;
  }

  &:active {
    @apply bg-gray-400;
  }
}

.item.back {
  @apply font-bold text-sm bg-gray-200;

  &:hover {
    @apply bg-gray-300;
  }
}

.drilldown-transition {
  @apply h-auto opacity-100 transition-opacity duration-500 ease-in-out;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.selected-item {
  animation: fadeOut 3000ms ease-in-out;
  animation-iteration-count: 1;
}
