






























































































@use 'sass:math';
$w-preview-sm: 240px;

.crease {
    background: rgb(255,255,255);
    background: linear-gradient(
        180deg,
        rgba(255,255,255,0) 45.5%,
        rgba(120,120,120,0.05) 47.5%,
        rgba(120,120,120,0.4) 50%,
        rgba(255,255,255,0) 50.1%,
        rgba(255,255,255,0) 100%
    );
}
.image-lg {
    position: absolute;
    width: 100%;
}
.image-sm {
    position: absolute;
    width: 100%;
}
.preview-lg {
    position: relative;
    overflow: hidden;
    width: 100%;
}
.preview-sm {
    height: math.div($w-preview-sm * 2, 3);
    overflow: hidden;
    position: relative;
    width: $w-preview-sm;
}
.post-it-preview{
    height: $w-preview-sm;
    width: $w-preview-sm;
    overflow: hidden;
    position: relative;
}
.discard-button {
    @apply shadow ml-2 px-1.5 py-0.5 bg-red-500 border-red-500 h-1/2 rounded-md text-white text-xs items-start hover:opacity-90;
}
.aspect-32 {
    padding-bottom: 150%;
}
