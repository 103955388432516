










































































































































.search-box {
    @apply flex flex-col sm:flex-row items-center items-stretch lg:mb-0 mb-6 sm:mb-2 px-6 sm:px-0;
}
.column-header {
    @apply text-gray-700 text-xs uppercase tracking-widest overflow-hidden text-center;
}
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
