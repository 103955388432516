






















































































































































.small-red-button {
    &:hover{
      @apply bg-red-500 border-red-500 ;
    }
    @apply m-2 py-2 bg-gray-500 text-white border-solid border border-gray-500 inline-flex;
    @apply justify-center items-center flex-1 transition-all px-3 hover:opacity-90 font-bold;
    @apply focus:outline-none focus:shadow-outline uppercase tracking-wider text-sm rounded-full;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
