












































































































































































































.list {
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.pill-box {
    @apply text-sm px-2 py-1 rounded mr-2 bg-gray-200;
}
