



































































































































































.dropdown-button {
    @apply inline-flex justify-between w-full items-center text-left rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500;
}

.dropdown-menu {
    @apply origin-top-left border border-gray-400 absolute left-0 mt-2 w-full rounded-md bg-white focus:outline-none z-40 max-h-[300px] overflow-y-auto;
}

