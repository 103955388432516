























































































.button {
    @apply shadow min-w-button inline-flex justify-center items-center flex-1 px-5 font-bold uppercase text-sm rounded-md hover:opacity-90 focus:outline-none focus:shadow-outline;
}
