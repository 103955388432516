























































































































































































.layout-tag {
    @apply inline-flex grow-0 items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800;
}
