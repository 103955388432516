



















.flyout{
    min-width:10rem;
    max-height:17rem;
    @apply absolute w-max overflow-y-scroll top-full z-20 border border-gray-200 bg-white right-0 rounded shadow mt-2;
}
