



































@use 'sass:math';
$w-preview-sm: 240px;

.preview-lg {
    max-width: 760px;
}

@screen md {
    .preview-sm {
        height: math.div($w-preview-sm * 2, 3);
        width: $w-preview-sm;
    }
}
