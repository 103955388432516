



























































.multiselect__tags {
    @apply border border-solid border-gray-400;
}
.multiselect__placeholder, .multiselect__input {
    @apply text-base text-gray-600;
    line-height: 24px;
    padding: 0;
}
.multiselect__tag {
    margin-bottom: 4px;
    padding-right: 30px;
}
.multiselect__tag-icon {
    border-radius: 0 5px 5px 0;
}
.multiselect__tag-icon:after {
    @apply text-lg text-white;
}
.multiselect__tag-icon:hover {
    @apply bg-gray-700;
}
.multiselect__option--highlight,
.multiselect__option--highlight:after {
    @apply bg-brand-light;
}
.multiselect__select {
    height: auto;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 14px;
    width: auto;
}
.multiselect__select::before {
    @apply text-gray-700;
    border: none;
    content: "\f078";
    font-family: 'Font Awesome 5 Free', serif;
    font-weight: 900;
    margin: 0;
}
.invalid .multiselect__tags {
    @apply border-red-500;
}
