
































































































































































































































































.deleted-header {
    @apply mt-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800;
}
