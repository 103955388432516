
































.loader {
    display: inline-block;
    position: relative;
}

.loader-animation {
    animation: loader 1200ms cubic-bezier(0.4, -0.35, 0.25, 1.75) infinite;
    border-bottom: 6px solid rgba(#ccc, 0.85);
    border-left: 6px solid rgba(#ccc, 0.85);
    border-radius: 50%;
    border-top: 6px solid rgba(#ccc, 0.85);
    height: 100%;
    width: 100%;
}

@keyframes loader {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
