






































































.v-checkbox input:checked + svg {
    display: block !important;
}
