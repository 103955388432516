








































.upload-button {
  @apply flex flex-col items-center justify-center w-full h-full py-6 rounded-lg bg-gray-100 cursor-pointer hover:ring-2 hover:ring-brand-dark;
}
