















































































































































.contact-avatar {
    @apply w-20 h-20 rounded-full mr-4 overflow-hidden bg-gray-300 border border-gray-300;
    @apply flex items-center justify-center;
}

.view-link {
    @apply text-brand-regular uppercase tracking-wider text-sm hover:underline font-semibold;
}
