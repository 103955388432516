






































































































































.manage-contacts-modal {
    max-width: 900px;
}
