

































































































.discard-button {
    @apply shadow ml-2 px-1.5 py-0.5 bg-red-500 border-red-500 h-1/2 rounded-md text-white text-xs items-start hover:opacity-90;
}
