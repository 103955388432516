






























































































.list > div {
    @apply border-b
}
.list > div:last-child {
    @apply border-b-0
}
