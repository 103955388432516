











































































































































































































































.scale-button {
    @apply cursor-pointer rounded-full leading-none w-10 h-10 flex items-center justify-center border border-brand-regular;
}
