















































.mention-container {

    @apply flex flex-shrink -mb-1 justify-end border-r border-l border-t border-gray-400 rounded-t p-3 pb-4;

    ::-webkit-scrollbar {
        display: none;
    }
}

