












































































.dropdownMenu {
    button,
    a {
        @apply text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 w-full text-left;
        &:disabled {
            @apply opacity-50 cursor-not-allowed;
        }
    }
}

.option-menu {
    @apply relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-inset focus:ring-brand-regular focus:border-brand-regular;
}

.trigger {
    @apply shadow border border-gray-300 rounded-md overflow-hidden inline-flex divide-x divide-gray-300;
}

.priorityOptions {
    @apply divide-x divide-gray-300;
    button,
    a {
        @apply relative inline-flex items-center px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-inset focus:ring-brand-regular focus:border-brand-regular;

        &.red {
            @apply bg-red-500 text-white hover:opacity-90;
        }
        &.gray {
            @apply bg-gray-50 hover:opacity-90;
        }
    }
}
