












































































































































.stale-badge {
    @apply inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500;
}

.stale-badge-0 {
    @apply bg-yellow-50 text-yellow-800 ring-yellow-500;
}

.stale-badge-1 {
    @apply bg-orange-50 text-orange-800 ring-orange-500;
}

.stale-badge-2 {
    @apply bg-red-50 text-red-800 ring-red-500;
}
