


























































































































































.article-link {
    @apply flex items-center justify-center py-4 text-sm text-gray-700 font-medium hover:text-gray-500 w-1/2;
}
