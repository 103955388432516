.-translate-50\/50 {
    transform: translate(-50%, -50%);
}

.-translate-t-50 {
    transform: translateY(-50%);
}

.scale-1\/4 {
    transform: scale(0.25);
}

.scale-50\/50 {
    transform: scale(0.5);
}

.scale-3\/4 {
    transform: scale(0.75);
}

.transform-origin-left-center {
    transform-origin: left center;
}

.transform-rotate-x180 {
    transform: rotateX(180deg);
    transform-style: preserve-3d;
}

.transform-rotate-y180 {
    transform: rotateY(180deg);
    transform-style: preserve-3d;
}
