
















































































.pricing-card {
    transition: border 0.3s ease-in-out;
    @apply flex flex-col border border-gray-300 gap-4 mx-auto w-full;
    @apply text-gray-600 p-6 bg-gray-50 rounded shadow-sm hover:shadow-lg hover:border-brand-regular relative;
}

.badge {
    @apply top-4 -mt-6 -mx-6 text-center font-bold right-2 leading-none;
    @apply bg-brand-light text-white px-4 py-2 rounded-t uppercase text-sm;
}

.badge.unpublished {
    @apply text-inherit bg-gray-400;
}

.focused {
    transition: border 0.2s ease-in-out;
    @apply border border-red-500 rounded box-content;
}

.edit-button{
    @apply opacity-0;
}

.pricing-card:hover .edit-button {
    transition:opacity 0.2s ease-in-out;
    @apply opacity-100;
}

