



































@tailwind variants;

.label {
    @apply flex flex-col relative block text-gray-600;
}

.label-text {
    @apply uppercase text-sm pointer-events-none absolute tracking-wider truncate transition-all;
}

.label-readonly {
    @apply bg-transparent text-gray-700 px-3 text-xs ml-0;
}

.label-focused {
    @apply bg-white text-gray-700 px-2 text-xs;
}
