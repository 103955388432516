

















































































































































































.query-tag-filters {
    @apply flex flex-col w-full lg:flex-row gap-2;
}
.dropdown-item {
    @apply py-1 px-3 font-normal text-left hover:bg-gray-100;
}
