


































































































































































































































































































































































































.gift-image {
    width: 100px;
}

.collateral-item {
    @apply mb-4;
}

.collateral-item.unchecked .description {
    @apply text-gray-600
}

.collateral-item:last-child {
    @apply mb-0;
}
.container {
    max-width: unset;
}
.loader {
    @apply flex justify-center items-center bg-gray-600 opacity-75 absolute rounded-lg z-10;
    height: 100px;
    left: 50%;
    margin-left: -50px;
    top: 200px;
    width: 100px;
}
.preview-container {
    max-width: 800px;
}

@screen lg {
    .summary {
        width: 350px;
    }
}
