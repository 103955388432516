






























































.vue-slider-dot-tooltip-inner {
  @apply bg-brand-regular border-brand-regular;
}
