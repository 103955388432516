



















































































































































































































































.account-navigation {
    @apply relative z-0 rounded-lg shadow grid grid-cols-2 divide-y divide-x divide-gray-200 sm:flex sm:divide-y-0;
}

.navigation-tab {
    @apply relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center;
    @apply hover:bg-gray-50 focus:z-10;
}
