

















































































































.content {
    height: 40vh;
    overflow: auto;
}

.selected {
    @apply bg-gray-200;
}

.doodle {
    @apply flex flex-col flex-1 p-4 cursor-pointer rounded border border-gray-400 m-2 hover:bg-gray-100
}
