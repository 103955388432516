
























































































































.article-link {
    @apply relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium hover:text-gray-500 w-full flex;
}
