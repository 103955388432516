



























































































































.job-actions > * {
    margin-bottom: 4px;
    width: 100%;
}
