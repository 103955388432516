

































































































































































































.article-list {
    @apply grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 p-2 rounded;
}

.article {
    @apply block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden;
    @apply focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500;
}

.article-download-image {
    @apply hidden group-hover:inline-flex absolute right-2 top-2 items-center p-3 border border-transparent;
    @apply rounded-full shadow-sm text-white bg-brand-regular focus:outline-none;
}
