












































.image {
    min-height: 80px;
    height: 80px;
}
.main-image {
    height: 300px;
}
.upload-button {
    @apply border rounded flex items-center bg-gray-200 gap-2 px-6 image uppercase tracking-wider text-sm font-bold text-gray-800;
}
