





























































































































.list {
    display: grid;
    grid-template-columns: 1fr 2fr;
}
