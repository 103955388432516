





































.closed:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    height: 25px;
}
