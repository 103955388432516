





































.tab-button {
    @apply relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 first:rounded-l-lg last:rounded-r-lg;
}

.tab-button .label {
    @apply hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block;
}

