

























































































































































































































































































































































































































































































.notes-title {
    @apply uppercase text-sm text-gray-700 pointer-events-none tracking-wider truncate border-b border-gray-400;
}

.button-icon {
    @apply text-white bg-gray-400 rounded-full w-6 h-6 flex items-center justify-center;
}
