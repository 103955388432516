




































































































.list {
    @apply border-b;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.list:last-child {
    @apply border-b-0;
}

.main-address {
    line-height: 1;
    letter-spacing: 1.2px;
}
