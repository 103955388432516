// ICONS
@import url('https://use.typekit.net/bdx5ixa.css');

// VUE TOOLTIP
@import 'floating-vue/dist/style.css';

// CUSTOM UTILTIES
@import './custom-utilities/gradients';
@import './custom-utilities/transform';
@import './custom-utilities/transition';
@import './custom-utilities/text';

// VUE ANIMATIONS
@import './vue-animation/index';

// todo move to base file
.order-list {
    display: grid;
    grid-template-columns: auto 1fr;
    row-gap: 0.5rem;
}
