


































































































































































































































































































.shadow-photo {
    @apply justify-center items-center bg-gray-400 inline-flex px-2 py-1 w-32 h-24
}
