





































.button {
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  @apply flex gap-2 justify-center items-end px-4 py-3 text-gray-800 bg-gray-300 border border-gray-300;
  @apply rounded shadow focus:shadow-outline text-sm;

  &:hover {
    @apply opacity-90;
  }
}

