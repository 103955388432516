




































































































































.action-button {
    @apply inline-flex items-center p-1 rounded-full text-sm font-medium;
}

.image-name-input {
    @apply outline-none flex-1 block w-full rounded-md mr-2 sm:text-sm border-b truncate disabled:bg-gray-300 px-2 py-1;
}

.image {
    @apply focus-within:ring-4 focus-within:ring-brand-regular hover:ring-4 hover:ring-brand-regular cursor-pointer;
}

.deleted-image {
    @apply opacity-25 group-hover:opacity-100 cursor-default group-hover:ring-0 transition-opacity;
}

.checkbox {
  @apply absolute -top-2 -right-2 cursor-pointer z-50;

  input {
    @apply w-6 h-6 appearance-none checked:bg-brand-regular checked:border-brand-regular border-2 border-gray-300;
    @apply rounded-full cursor-pointer;
  }
}
