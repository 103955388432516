





























































































































































.collateral-search {
    @apply flex flex-col items-center items-stretch mb-6 px-6 max-w-md sm:mb-2 sm:px-0 sm:flex-row lg:mb-0;
}
