






















































.google-input {
  @apply p-2 w-full appearance-none block rounded focus:outline-none transition-all text-gray-800 bg-white;
  @apply focus:border-brand-regular border border-solid border-gray-400;
}
