
























































































.upload-button {
    @apply border rounded flex items-center bg-gray-200 image gap-2 px-6 uppercase tracking-wider text-sm font-bold text-gray-800;
}
.container {
    display: grid;
    grid-template-rows: 2fr auto;
}
.mainImage {
    height: 300px;
}
.imageList {
    gap: 0.5rem;
}
.image {
    min-height: 80px;
    height: 80px;
}
.delete-button {
    display: none;
}
.image:hover > .delete-button {
    display: block;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
