














































































































































































































































.ProseMirror:focus {
    outline: none;
}

.ProseMirror p.is-editor-empty:first-child:before {
    content: attr(data-placeholder);
    @apply text-gray-500 pointer-events-none h-0 float-left;
}

[data-type='interpolation'] {
    @apply border rounded py-1 px-2 bg-gray-200;
}

.typography-select {
    @apply block w-full pl-3 pr-10 py-3 px-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md;
}

