





















































.tab-view {
    @apply relative z-0 rounded-lg shadow grid grid-cols-2 divide-y divide-x divide-gray-200 sm:flex sm:divide-y-0
}

