









































.notification-dropdown {
    @apply
    fixed md:absolute
    top-0 right-0 md:top-auto md:right-1
    w-screen h-screen md:max-w-sm md:h-fit md:max-h-[70vh]
    flex flex-col
    bg-white shadow overflow-hidden border
    md:rounded-lg md:mt-1
}

.empty-message {
    @apply p-4 text-center text-gray-600
}

.toggle-show-all-button {
    @apply text-sm text-blue-600 dark:text-blue-500 hover:underline pr-4
}
