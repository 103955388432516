






























































































































































































































































@media (min-width: 768px) {
    .wrapper {
        display: grid;
        grid-template-rows: auto 1fr;
    }
}

.contacts-search {
    @apply flex flex-col sm:flex-row items-center items-stretch lg:mb-0 mb-6 sm:mb-2 px-6 sm:px-0;
}

.section-header {
    @apply flex flex-col p-6 bg-white rounded-sm shadow-sm fixed bottom-0 left-0 w-full border-t-2 z-20 md:relative md:mb-4;
}
