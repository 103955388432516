











































































































































































































































































































































































































.link {
    display: grid;
    grid-template-columns: 40px 1fr;
}

.active-link > .absolute {
    display: block;
}

.container {
    @apply w-nav -left-nav;
}

.container.open {
    @apply left-0;
}

.nav {
    @apply flex flex-col bg-white h-screen border-r border-gray-300 overflow-y-scroll fixed top-0 mt-18 md:mt-16 bottom-0 z-30 pt-5 pb-10 transition-all;
}

@screen md {
    .container {
        @apply left-0;
    }
}
