







































.link {
    @apply shadow rounded bg-white p-4 flex items-center justify-center gap-3 hover:text-brand-regular hover:bg-gray-50;
}
