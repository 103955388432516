










































































































ul.dropdown-menu.textcomplete-dropdown {
    @apply absolute flex flex-col right-0 shadow-lg bg-white rounded-sm z-20 min-w-content w-40;
}

ul.dropdown-menu.textcomplete-dropdown li {
    padding: 5px 10px;
    cursor: pointer;
}

ul.dropdown-menu.textcomplete-dropdown li.active {
    @apply bg-gray-300;
}
