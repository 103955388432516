





















































































.draggable {
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
}
